/* istanbul ignore file */

import { Toetsnorm, ToetsnormOutput } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { useContext, useEffect } from "react";

import useAbortableFetch from "use-abortable-fetch";
import { useRequestInit, ForceRerenderContext } from "adviesbox-shared";

type useToetsnormDataOutput = {
  loading: boolean;
  data: { [key: string]: Toetsnorm } | null;
  error: Error | null;
};

export function useToetsnormData(): useToetsnormDataOutput {
  const loadingDone = useContext(ForceRerenderContext);

  const { requestInit, settings } = useRequestInit();
  const url = `${settings.berekeningenFormsOrigin}/Hypotheek/Toetsnormen`;
  const { error, data, loading } = useAbortableFetch<ToetsnormOutput>(url, requestInit);

  useEffect((): void => {
    if (!loading && !!data && loadingDone) {
      loadingDone();
    }
  }, [loading, data, loadingDone]);

  if (typeof data === "string") {
    return { loading: false, data: null, error: new Error("Onverwachte data ontvangen") };
  }

  if (data === null) {
    return { loading: false, data: null, error: new Error("Geen data ontvangen") };
  }
  if (!data.toetsnormen) {
    return { loading: false, data: null, error: new Error("Geen toetsnormen ontvangen") };
  }

  return { error, loading, data: data.toetsnormen };
}
