import { Icon } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { UitslagOptions } from "../.generated/berekeningen-forms/berekeningen-formstypes";

export const castUitslag2Icon = (uitslag: UitslagOptions): React.ReactElement => {
  let icon: ReactElement = <></>;

  switch (uitslag) {
    case UitslagOptions.Groen:
      icon = <Icon name="vink" alt="vink" />;
      break;
    case UitslagOptions.Oranje:
      icon = <Icon name="uitroepteken" multiColor={true} alt="uitroepteken" />;
      break;
    case UitslagOptions.Rood:
      icon = <Icon name="kruis" alt="kruis" />;
      break;
    default:
    case UitslagOptions.Wit:
      icon = <></>;
      break;
  }

  return <div className="w-100 h-100 d-flex justify-content-center align-items-center">{icon}</div>;
};
