import { ToetsrenteBerekeningOptions } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { createISWSideEffect, initISWSideEffect } from "../../shared/components/isw-side-effects/create-isw-helpers";
import { MaximaleHypotheekState } from "./maximale-hypotheek-schema";

const aanvragerSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (has.aanvrager.toetsinkomenAanvrager1.changed) {
    draft.aanvrager.pensioenInkomenAanvrager1.berekendBedrag = (draft.aanvrager.toetsinkomenAanvrager1 || 0) * 0.7;
  }
  if (has.aanvrager.toetsinkomenAanvrager2.changed) {
    draft.aanvrager.pensioenInkomenAanvrager2.berekendBedrag = (draft.aanvrager.toetsinkomenAanvrager2 || 0) * 0.7;
  }
});

const toetsrenteSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (
    has.toetsrente.berekeningOpBasis.changed &&
    draft.toetsrente.berekeningOpBasis === ToetsrenteBerekeningOptions.SpecificatieToetsrente
  ) {
    draft.toetsrente.gewensteRentevastperiode = null;
  }
  if (
    has.toetsrente.berekeningOpBasis.changed &&
    draft.toetsrente.berekeningOpBasis === ToetsrenteBerekeningOptions.GewensteRentevastperiode
  ) {
    draft.toetsrente.gewensteRentevastperiode = 10;
  }
});

const leningdelenSideEffects = createISWSideEffect<MaximaleHypotheekState>(({ has, draft }): void => {
  if (has.leningdelenUitHetVerleden.meenemen.changed && !draft.leningdelenUitHetVerleden.meenemen) {
    draft.leningdelenUitHetVerleden.leningdelen = [];
  }
});

const maximaleHypotheekSideEffects = createISWSideEffect<MaximaleHypotheekState>((bag): void => {
  aanvragerSideEffects(bag);
  toetsrenteSideEffects(bag);
  leningdelenSideEffects(bag);
  if (
    bag.has.aanvrager.changed ||
    bag.has.leningdelenUitHetVerleden.changed ||
    bag.has.toetsrente.changed ||
    bag.has.uitgangspunten.changed
  ) {
    bag.draft.dataHasChanged = true;
  }
});

export const syncMaximaleHypotheekBerekeningSideEffects = initISWSideEffect(maximaleHypotheekSideEffects)();
