import React, { useContext, ReactElement } from "react";
import UserDetailsContext from "../user-details/user-details-context";

const AdviseurNaam = (): ReactElement | null => {
  const { userDetails } = useContext(UserDetailsContext);
  if (!userDetails) {
    return null;
  }

  function getNaamAanvrager(aanvrager: any): string {
    return `${(aanvrager.voorletters || "") + "\xa0"}${
      aanvrager.tussenvoegsels ? aanvrager.tussenvoegsels + "\xa0" : ""
    }${aanvrager.achternaam || ""}`.trim();
  }

  return userDetails && <span>{getNaamAanvrager(userDetails)}</span>;
};

export default AdviseurNaam;
