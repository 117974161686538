const formatter = (bedrag: number, decimals?: number): string => {
  return new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals // TO-DO - Dit moet ook een default van 0 hebben.
  }).format(bedrag);
};

export const optellen = (bedragen: (number | null)[]): number | null => {
  return bedragen.reduce((x, y): number => (x ?? 0) + (y ?? 0), null);
};

// Standaard afronden op 6 decimalen omdat dat de nauwkeurigheid is bij het opslaan bij platform
export function afronden(bedrag: number, decimalen?: number): number;
export function afronden(bedrag: number | null, decimalen?: number): number | null;
export function afronden(bedrag: number | null, decimalen = 6): number | null {
  if (typeof bedrag === "number") {
    return Math.round(Math.pow(10, decimalen) * bedrag) / Math.pow(10, decimalen);
  } else {
    return null;
  }
}

export const bedragFormat = (bedrag: number | null, decimals?: number, afrondenBoven?: number): string => {
  if (bedrag && Math.abs(bedrag) > (afrondenBoven ?? 1000)) bedrag = Math.round(bedrag);
  return formatter(bedrag || 0, decimals);
};

export const nbspNaarSpaties = (tekst: string): string => {
  const re = new RegExp(String.fromCharCode(160), "g");
  return tekst.replace(re, " ");
};
