import { Label, LabelProps, LoadingSpinner, RadioInputGroup, SelectInput } from "adviesbox-shared";
import classNames from "classnames";
import React, { InputHTMLAttributes, ReactElement, ReactNode } from "react";
import { Toetsnorm } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { Direction, LabelValuePair, LabelValuePairs } from "../../shared/types";
import { useToetsnormData } from "../infra/use-toetsnorm-data";

type ToetsnormProps = {
  caption: string;
  name: string;
  appendChildren?: ReactNode;
  readonly?: boolean;
  fieldSize?: "xl" | "l" | "m" | "s";
  visible?: boolean;
  defaultValue?: string | number;
  emptyValue?: string;
  parseValueToNumber?: boolean;
} & LabelProps &
  InputHTMLAttributes<HTMLSelectElement>;

const getToetsnormOptions = (data: Toetsnorm[]): LabelValuePairs =>
  data.map(
    (c: Toetsnorm): LabelValuePair => ({ label: `${c.omschrijving}`, value: c.jaar?.toString() || "" })
  );

export const ToetsnormInput = ({
  name,
  caption,
  visible,
  className,
  labelColSize = 7,
  tooltip,
  appendChildren,
  readonly,
  onChange,
  fieldSize,
  defaultValue,
  emptyValue,
  parseValueToNumber
}: ToetsnormProps): ReactElement => {
  const { loading, data, error } = useToetsnormData(); //TODO: catch error
  const inputColSize = labelColSize < 12 ? 12 - labelColSize : 12;

  if (error && !loading) return <>Er is iets fout gegaan</>;

  const toetsnormen: Toetsnorm[] = data ? Object.values(data) : [];

  const options = getToetsnormOptions(toetsnormen);

  const renderAsRadio = !readonly && options.length < 4;
  const input = renderAsRadio ? (
    <RadioInputGroup options={options} name={name} layout={Direction.Vertical} onChange={onChange as any} />
  ) : (
    <SelectInput
      options={options}
      name={name}
      readonly={readonly}
      fieldSize={fieldSize}
      defaultValue={defaultValue}
      emptyValue={emptyValue}
      onChange={onChange}
      parseValueToNumber={parseValueToNumber}
    />
  );

  return (
    <div
      className={classNames("test form-group form-row", className, {
        invisible: visible === false
      })}
    >
      <div className={`col-${labelColSize}`}>
        <Label caption={caption} name={name} tooltip={tooltip} />
      </div>
      <div className={`col-${inputColSize}`}>
        <div className="input-group">
          {loading ? <LoadingSpinner /> : input}
          {appendChildren}
        </div>
      </div>
    </div>
  );
};
