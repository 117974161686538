import { LoginPage, SigninOidc } from "adviesbox-shared";
import React, { ReactElement } from "react";
import { Route } from "react-router";
import logo from "../assets/new-brand-logo.svg";
const UnauthenticatedBerekeningenApp = (): ReactElement => (
  <>
    <Route path="/signin-oidc" component={SigninOidc} />
    <LoginPage appLogo={logo} />
  </>
);

UnauthenticatedBerekeningenApp.displayName = "UnauthenticatedBerekeningenApp";

export default UnauthenticatedBerekeningenApp;
