import { LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import { HypotheekvormOptions } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getMaandlastenBerekeningTextResources } from "./maandlasten-berekening-resources";
import { nullableLocalDateSchema, jaarMaandInputSchema } from "adviesbox-shared";

export const uitgangspuntenSchema = Yup.object({
  hypotheekVorm: yupEnum(HypotheekvormOptions).default(HypotheekvormOptions.Annuitair),
  hypotheekBedrag: Yup.number()
    .min(1000, getMaandlastenBerekeningTextResources("hypotheekbedragMin"))
    .max(99999999, getMaandlastenBerekeningTextResources("hypotheekbedragMax"))
    .nullable()
    .default(null)
    .required(getMaandlastenBerekeningTextResources("hypotheekBedragVerplicht")),
  rentePercentage: Yup.number()
    .nullable()
    .default(null)
    .min(0.01, getMaandlastenBerekeningTextResources("renteMinimaal"))
    .max(20, getMaandlastenBerekeningTextResources("renteMaximaal"))
    .required(getMaandlastenBerekeningTextResources("rentePercentage")),
  ingangsDatum: nullableLocalDateSchema
    .default(
      LocalDate.now()
        .plusYears(1)
        .withDayOfMonth(1)
        .withMonth(1)
    )
    .required(getMaandlastenBerekeningTextResources("ingangsdatumVerplicht")),
  looptijd: jaarMaandInputSchema
    .nullable()
    .default({ jaren: 30, maanden: 0 })
    .required()
});

export type UitgangspuntenType = Yup.InferType<typeof uitgangspuntenSchema>;

export const maandlastResultaatSchema = Yup.object({
  aflossing: Yup.number().nullable(),
  rentebedrag: Yup.number().nullable(),
  brutoLast: Yup.number().nullable(),
  restantHoofdsom: Yup.number().nullable(),
  jaar: Yup.number().nullable(),
  maand: Yup.number().nullable()
});

export interface YearIsExtendedType {
  jaar: number;
  extended?: boolean;
  ttlAflossing: number;
  ttlRente: number;
  ttlBrutolast: number;
  decemberRestantHoofdsom: number;
}
export interface IndexIsExtendedType {
  [key: string]: any;
}

export const maandlastenBerekeningSchema = Yup.object({
  uitgangspunten: uitgangspuntenSchema,
  dataHasChanged: Yup.bool(),
  maandlastResultaten: Yup.array(maandlastResultaatSchema).default([]),
  sortedMaandlasten: Yup.array(maandlastResultaatSchema).default([]),
  extendedByYear: Yup.array(Yup.object<YearIsExtendedType>()).default([]),
  extendedByIndex: Yup.object<IndexIsExtendedType>()
    .nullable()
    .default(null)
});

export type MaandlastResultaatType = Yup.InferType<typeof maandlastResultaatSchema>;
export type MaandlastenBerekeningState = Yup.InferType<typeof maandlastenBerekeningSchema>;
export type MaandlastenBerekeningProps = Yup.InferType<typeof maandlastenBerekeningSchema>;
