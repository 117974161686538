import { Afbeelding, hasValue, Icon, useRequestInit } from "adviesbox-shared";
import moment from "moment";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useAbortableFetch from "use-abortable-fetch";
import AdviseurNaam from "../../auth/AdviseurNaam";
import { OrganisatiesOutput } from "../../.generated/licenties/licentiestypes";
import { bedragFormat } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import { percentageFormat } from "../../shared/utils/percentage-format";
import UserDetailsContext from "../../user-details/user-details-context";
import {
  MaandlastenBerekeningState,
  MaandlastResultaatType,
  UitgangspuntenType,
  YearIsExtendedType
} from "../infra/maandlasten-berekening-schema";
import classes from "../maandlasten-berekening.module.scss";
import {
  getCurrentDateFormatted,
  printPDF
} from "./maandlasten-berekening-pdf-helpers/maandlasten-berekening-pdf-helpers";

/* istanbul ignore file */

type MaandlastenBerekeningPDFProps = {
  values: MaandlastenBerekeningState;
  sortedData: MaandlastResultaatType[];
  initial: MaandlastResultaatType[];
};

const extendedRowsLength = (extendedByYear: YearIsExtendedType[]): number => {
  let ttlLength = extendedByYear.length;
  extendedByYear.forEach(v => {
    if (v.extended) {
      ttlLength += 12; // 12 months/rows
    }
  });

  return ttlLength;
};

const extendedRowsData = (extendedByYear: YearIsExtendedType[], data: MaandlastResultaatType[]): any[] => {
  let completeData: any[] = [];
  extendedByYear.forEach(e => {
    completeData.push(e);

    if (e.extended) {
      completeData = completeData.concat(data.filter(v => v.jaar === e.jaar));
    }
  });

  return completeData;
};
export const MaandlastenBerekeningPDF = ({
  values,
  sortedData,
  initial
}: MaandlastenBerekeningPDFProps): ReactElement => {
  const [data, setData] = useState<any>([]);
  const { settings, requestInit } = useRequestInit();
  const { userDetails } = useContext(UserDetailsContext);

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);
  const extendedByYear: YearIsExtendedType[] = values.extendedByYear;

  useEffect(() => {
    if (sortedData.length > 0) {
      setData(sortedData);
      return;
    }

    setData(initial);
  }, [sortedData, initial]);
  const organisatieLogo =
    organisatie.data && typeof organisatie.data !== "string" && organisatie.data.organisaties && organisatieId
      ? organisatie.data.organisaties[organisatieId].logo
      : "";

  const maandlastenBerekening = [...extendedRowsData(extendedByYear, data)];
  const amountOfLinesFirstPage = 50;
  const amountOfLinesPerPage = 55;
  const amountOflinesTotal = extendedRowsLength(extendedByYear);
  let amountOfpages = Math.ceil((amountOflinesTotal - amountOfLinesFirstPage) / amountOfLinesPerPage + 1);

  const bedrijfslogo = new Image();
  bedrijfslogo.src = `data:image/png;base64,${organisatieLogo}`;

  const renderHeader = (): ReactElement => {
    return (
      <>
        <div className={classes.pdf_header_container}>
          <h1 className={classes.pdf_header}>Maandlasten</h1>
          <div className={classes.pdf_header}>
            <Afbeelding
              name="organisatie.logo"
              alt="bedrijfslogo"
              location={bedrijfslogo.src}
              style={{ maxHeight: 60 }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderFooter = (): ReactElement => {
    return (
      <>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.pdf_footer}>
          <div className={classes.pdf_footer_header}>
            <span>
              Deze bedragen zijn slechts een indicatie van wat werkelijk mogelijk is. Controle van de acceptatienormen
              van de gekozen maatschappij dient te geschieden op het tabblad &apos;acceptatie&apos;. Definitieve
              acceptatie geschiedt altijd door de gekozen maatschappij zelf.
            </span>
          </div>
          <div className={classes.pdf_footer_sub}>
            <span>
              Overzicht verzorgd door{" "}
              <span>
                <AdviseurNaam />{" "}
              </span>
              van{" "}
              {organisatie.data &&
              typeof organisatie.data !== "string" &&
              organisatie.data.organisaties &&
              organisatieId
                ? organisatie.data.organisaties[organisatieId].naam
                : ""}{" "}
              op {getCurrentDateFormatted()}.
            </span>
            <span className={classes.pdf_footer_sub_rights}>
              Aan dit overzicht kunnen geen rechten worden ontleend.
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderUitgangspunten = (uitgangspunten: UitgangspuntenType, onlyUitgangsPunten: boolean): ReactElement => {
    return (
      <>
        <table className={onlyUitgangsPunten ? classes.pdf_padding : ""}>
          <thead>
            <tr>
              <td className={classes.pdf_table_uitgangspunten_header_left}>Uitgangspunten berekening</td>
              <td className={classes.pdf_table_uitgangspunten_header_right}></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.pdf_table_data_info}>Hypotheekvorm</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {uitgangspunten.hypotheekVorm ? uitgangspunten.hypotheekVorm : ""}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Hypotheekbedrag</td>
              <td className={`${classes.pdf_table_data}`}>
                {uitgangspunten.hypotheekBedrag ? bedragFormat(uitgangspunten.hypotheekBedrag) : bedragFormat(0)}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Rentepercentage</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {uitgangspunten.rentePercentage
                  ? `${percentageFormat(uitgangspunten.rentePercentage, 2)}%`
                  : `${percentageFormat(0, 0)}%`}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Ingangsdatum</td>
              <td className={`${classes.pdf_table_data}`}>
                {uitgangspunten.ingangsDatum ? getFormattedDate(uitgangspunten.ingangsDatum) : null}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Looptijd</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {uitgangspunten.looptijd?.jaren} jaar, {uitgangspunten.looptijd?.maanden}{" "}
                {uitgangspunten.looptijd?.maanden === 1 ? "maand" : "maanden"}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderPage = (
    firstRender: boolean,
    index: number,
    lastPage: boolean,
    renderOnlyUitgangspunten: boolean,
    resultaat?: any[] | undefined
  ): ReactElement => {
    return (
      <div
        className={classes.pdf_hide}
        id={`print-container-${index}`}
        key={`print-container-${index}-key`}
        style={{ maxWidth: "30.3cm", minWidth: "30.3cm", display: "block" }}
      >
        <div className={classes.pdf_page} style={{ backgroundColor: "white" }}>
          {firstRender && !renderOnlyUitgangspunten && renderHeader()}
          {!renderOnlyUitgangspunten && resultaat && (
            <table className={`${classes.pdf_table} ${classes.pdf_table_dataTable}`}>
              <thead>
                <tr>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_first}`}>Jaar</th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_second}`}>Maand</th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_third}`}>
                    Aflossing
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_fourth}`}>Rente</th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_fifth}`}>
                    Bruto last
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_sixth}`}>
                    Restant hoofdsom
                  </th>
                </tr>
              </thead>
              <tbody>
                {resultaat.map((val, index) => {
                  return (
                    <React.Fragment key={index + "-values"}>
                      {hasValue(val.extended) && (
                        <tr key={index + "-aggregated-values"}>
                          <td>{val.jaar}</td>
                          <td>{""}</td>
                          <td className={classes.pdf_table_right_align}>
                            {val.ttlAflossing ? `${bedragFormat(val.ttlAflossing)}` : ""}
                          </td>
                          <td className={classes.pdf_table_right_align}>
                            {val.ttlRente ? `${bedragFormat(val.ttlRente)}` : ""}
                          </td>
                          <td className={classes.pdf_table_right_align}>
                            {val.ttlBrutolast ? bedragFormat(val.ttlBrutolast) : bedragFormat(0)}
                          </td>
                          <td className={classes.pdf_table_right_align}>
                            {val.decemberRestantHoofdsom ? bedragFormat(val.decemberRestantHoofdsom) : ""}
                          </td>
                        </tr>
                      )}
                      {!hasValue(val.extended) && (
                        <tr key={index + "-expanded-values"}>
                          <td>{""}</td>
                          <td>{val.maand ? moment.months(val.maand - 1 || 0) : ""}</td>
                          <td className={classes.pdf_table_right_align}>
                            {val.aflossing ? `${bedragFormat(val.aflossing)}` : bedragFormat(0)}
                          </td>
                          <td className={classes.pdf_table_right_align}>
                            {val.rentebedrag ? `${bedragFormat(val.rentebedrag)}` : bedragFormat(0)}
                          </td>
                          <td className={classes.pdf_table_right_align}>
                            {val.brutoLast ? bedragFormat(val.brutoLast) : bedragFormat(0)}
                          </td>
                          <td className={classes.pdf_table_right_align}>
                            {val.restantHoofdsom ? bedragFormat(val.restantHoofdsom) : bedragFormat(0)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          )}
          {(lastPage || renderOnlyUitgangspunten) &&
            renderUitgangspunten(values?.uitgangspunten, renderOnlyUitgangspunten)}
          {renderFooter()}
        </div>
      </div>
    );
  };

  const renderPages = (): ReactElement => {
    let lastPage = false;
    let uitgangsPuntenFitsOnLastPage = false;
    const counter = amountOfpages;
    const pages: ReactElement[] = [];
    if (maandlastenBerekening.length === 0) return <></>;
    for (let i = 1; i <= counter; i++) {
      const startFrom = 0;
      const until = (): number => {
        if (i === 1) {
          return amountOfLinesFirstPage;
        }

        return amountOfLinesPerPage;
      };

      const sliced = maandlastenBerekening.splice(startFrom, until());
      const amountOfLinesOnpage = sliced.length;
      const EnoughSpaceForUitgangspunten = (): boolean => {
        const check = amountOfLinesOnpage + 9;
        if (i === 1 && check >= amountOfLinesFirstPage) return false;
        if (i !== 1 && check >= amountOfLinesPerPage) return false;
        return true;
      };

      if (amountOfpages === i) {
        lastPage = true;
        if (EnoughSpaceForUitgangspunten()) uitgangsPuntenFitsOnLastPage = true;
      }

      if (i > amountOfpages) break;

      const page = renderPage(i === 1, i, uitgangsPuntenFitsOnLastPage, false, sliced);
      pages.push(page);

      if (lastPage && !uitgangsPuntenFitsOnLastPage) {
        amountOfpages = amountOfpages + 1;
        const uitgangspuntenIndex = i + 1;
        const uitgangsPunten = renderPage(
          uitgangspuntenIndex === 1,
          uitgangspuntenIndex,
          uitgangsPuntenFitsOnLastPage,
          true,
          sliced
        );
        pages.push(uitgangsPunten);
      }
    }

    return <>{pages.map(page => page)}</>;
  };

  return (
    <>
      {renderPages()}
      <Button onClick={async () => await printPDF(amountOfpages, "download")}>
        <Icon name="download" alt="download" multiColor={true} />
      </Button>
    </>
  );
};
