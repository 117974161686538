import { MenuConfigType } from "adviesbox-shared";

export const menuConfig: () => MenuConfigType[] = () => [
  {
    name: "berekeningen",
    lists: [
      {
        name: "maandlasten",
        title: "Maandlasten",
        link: `/Maandlasten`,
        icon: "adviesbox"
      },
      {
        name: "maximale-hypotheek",
        title: "Maximale hypotheek",
        link: `/maximale-hypotheek`,
        icon: "adviesbox"
      }
    ]
  }
];
