/* istanbul ignore file */
import { LocalDate } from "@js-joda/core";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { getDifferenceYearsMonths } from "../../shared/utils/dates";
import { MaximaleHypotheekState } from "./maximale-hypotheek-schema";
import { mapLocalDateToString } from "adviesbox-shared";

export const aowBepalenAanvragerAsync = createISWAsyncSideEffect<MaximaleHypotheekState>(
  async ({ draft, settings, fetchData, has }) => {
    if (draft.aanvrager.geboortedatumAanvrager1 && has.aanvrager.geboortedatumAanvrager1.changed) {
      const aanvragerAowDatum1 = `"${mapLocalDateToString(draft.aanvrager.geboortedatumAanvrager1)}"`;
      const dateString = await fetchData<string, string>({
        url: `${settings.berekeningenFormsOrigin}/Hypotheek/AowDatum`,
        body: aanvragerAowDatum1
      });
      const diff = getDifferenceYearsMonths(draft.aanvrager.geboortedatumAanvrager1, LocalDate.parse(dateString));
      draft.aanvrager.aowJaarMaandAanvrager1 = { jaren: diff.year, maanden: diff.month };
      draft.aanvrager.aowLeeftijdAanvrager1 = LocalDate.parse(dateString);
    }

    if (draft.aanvrager.geboortedatumAanvrager2 && has.aanvrager.geboortedatumAanvrager2.changed) {
      const aanvragerAowDatum2 = `"${mapLocalDateToString(draft.aanvrager.geboortedatumAanvrager2)}"`;

      const dateString = await fetchData<string, string>({
        url: `${settings.berekeningenFormsOrigin}/Hypotheek/AowDatum`,
        body: aanvragerAowDatum2
      });
      const diff = getDifferenceYearsMonths(draft.aanvrager.geboortedatumAanvrager2, LocalDate.parse(dateString));
      draft.aanvrager.aowJaarMaandAanvrager2 = { jaren: diff.year, maanden: diff.month };
      draft.aanvrager.aowLeeftijdAanvrager2 = LocalDate.parse(dateString);
    }
  }
);

export const asyncMaximaleHypotheekBerekeningSideEffects = initISWAsyncSideEffect<MaximaleHypotheekState>(
  ({ curr, runAsync }) => {
    if (curr.aanvrager.geboortedatumAanvrager1 || curr.aanvrager.geboortedatumAanvrager2) {
      runAsync(aowBepalenAanvragerAsync());
    }
  }
)();
