import React, { createContext, ReactElement, ReactNode, useCallback, useState, SetStateAction } from "react";
import { MenuInfo } from "../.generated/forms/formstypes";

type AppDataProviderProps = {
  children: ReactNode;
};

export type AppDataContextType = {
  reloadCount: number;
  reloadNavigation: () => void;
  menuInfo: MenuInfo;
  setMenuInfo: React.Dispatch<React.SetStateAction<MenuInfo>>;
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
};

export const AppDataContext = createContext({
  reloadCount: 0,
  reloadNavigation: () => 0,
  menuInfo: {} as MenuInfo,
  setMenuInfo: () => {},
  activePage: "",
  setActivePage: /* istanbul ignore next */ () => {}
} as AppDataContextType);

export const AppDataProvider = ({ children }: AppDataProviderProps): ReactElement => {
  const [reloadNavigationCounter, setReloadNavigationCounter] = useState(1);
  const reloadNavigation = useCallback(() => setReloadNavigationCounter(oldState => oldState + 1), [
    setReloadNavigationCounter
  ]);
  const [activePage, setActivePage] = useState("");
  const [menuInfo, setMenuInfo] = useState({} as MenuInfo);
  return (
    <AppDataContext.Provider
      value={{
        reloadCount: reloadNavigationCounter,
        reloadNavigation: reloadNavigation,
        menuInfo,
        setMenuInfo,
        activePage,
        setActivePage
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
