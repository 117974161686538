/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { initISWSideEffect } from "../shared/components/isw-side-effects/create-isw-helpers";
import { MaandlastenBerekeningState } from "./infra/maandlasten-berekening-schema";
/* istanbul ignore file */

export const syncMaandlastenResultaatSideEffects = initISWSideEffect<MaandlastenBerekeningState>(bag => {
  const { draft, has } = bag;

  if (has.extendedByIndex.changed && draft.extendedByIndex && draft.extendedByIndex !== {}) {
    const indexesObject = draft.extendedByIndex;
    const extendedIndexes = indexesObject && Object.keys(indexesObject).filter(index => !!indexesObject[index]);
    draft.extendedByYear = draft.extendedByYear.map((v, i) => {
      if (extendedIndexes.includes(i.toString())) {
        return { ...v, extended: true };
      }
      return { ...v, extended: false };
    });
  }

  if (has.extendedByYear.changed && !draft.extendedByYear.length) {
    draft.extendedByIndex = null;
  }
});
