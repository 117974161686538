import { DataGrid, Icon, useAdviesboxDataRepository, useRequestInit, FetchDataButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Column } from "react-table-6";
import { MaximaleHypotheekOutput } from "../.generated/berekeningen-forms/berekeningen-formstypes";
import { mapDlTargetToMaximaleHypotheekBerekeningUiField } from "./infra/map-maximale-hypotheek-dl-target-to-ui-field";
import { mapMaximaleHypothekenResult } from "./infra/map-maximale-hypotheek-result";
import { mapMaximaleHypotheekBerekeningUiToDl } from "./infra/map-maximale-hypotheek-ui-to-dl";
import { getMaximaleHypotheekTextResources } from "./infra/maximale-hypotheek-resources";
import { MaximaleHypotheekDataGridTemplates, MaximaleHypotheekState } from "./infra/maximale-hypotheek-schema";
import { getMaximaleHypotheekColumnTemplates } from "./maximale-hypotheek-data-grid-column-templates";
import { MaximaleHypotheekPDF } from "./maximale-hypotheek-pdf/maximale-hypotheek-pdf";

export type MaximaleHypotheekDataGridProps = {
  setBerekend: React.Dispatch<React.SetStateAction<boolean>>;
  berekend: boolean;
};

export const MaximaleHypotheekResultaat = ({ berekend, setBerekend }: MaximaleHypotheekDataGridProps): ReactElement => {
  const formik = useFormikContext<MaximaleHypotheekState>();
  const { values, isValid, setFieldValue } = formik;
  const { settings } = useRequestInit({
    extraHeaders: {
      "Content-Type": "application/json"
    }
  });

  const url = `${settings.berekeningenFormsOrigin}/Hypotheek/MaximaleHypotheek`;
  const { fetchData } = useAdviesboxDataRepository<MaximaleHypotheekOutput, MaximaleHypotheekState>(url, {
    method: "POST",
    getDataId: () => "",
    mapDlToUi: (_, res) => {
      const mappedResults = mapMaximaleHypothekenResult(Object.values(res?.maximaleHypotheken ?? []));

      const newValues: MaximaleHypotheekState = {
        ...values,
        dataHasChanged: false,
        maximaleHypotheken: mappedResults
      };
      setBerekend(true);

      return newValues;
    },
    mapUiToDl: mapMaximaleHypotheekBerekeningUiToDl,
    mapTargetToUiField: mapDlTargetToMaximaleHypotheekBerekeningUiField
  });

  const maxHypotheekColumns = (): Column[] => {
    if (!values.maximaleHypotheken || !values.maximaleHypotheken.length) return [];
    const columnTemplates = getMaximaleHypotheekColumnTemplates(!!values.uitgangspunten.nhg);
    const columns: { [key: number]: Column | null } = {
      [MaximaleHypotheekDataGridTemplates.uitslag]: null,
      [MaximaleHypotheekDataGridTemplates.productnaam]: null,
      [MaximaleHypotheekDataGridTemplates.toetsrente]: null,
      [MaximaleHypotheekDataGridTemplates.extraAflossing]: null,
      [MaximaleHypotheekDataGridTemplates.looptijd]: null,
      [MaximaleHypotheekDataGridTemplates.maximaleHypotheek]: null,
      [MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand]: null,
      [MaximaleHypotheekDataGridTemplates.inkomstenVerklaring1]: null,
      [MaximaleHypotheekDataGridTemplates.inkomstenVerklaring2]: null
    };

    values.maximaleHypotheken.forEach((a): void => {
      if (!columns[MaximaleHypotheekDataGridTemplates.uitslag] && !!a.uitslag)
        columns[MaximaleHypotheekDataGridTemplates.uitslag] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.uitslag];
      if (!columns[MaximaleHypotheekDataGridTemplates.toetsrente] && !!a.toetsrente)
        columns[MaximaleHypotheekDataGridTemplates.toetsrente] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.toetsrente];
      if (!columns[MaximaleHypotheekDataGridTemplates.looptijd] && !!a.looptijd)
        columns[MaximaleHypotheekDataGridTemplates.looptijd] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.looptijd];
      if (!columns[MaximaleHypotheekDataGridTemplates.productnaam] && !!a.productnaam)
        columns[MaximaleHypotheekDataGridTemplates.productnaam] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.productnaam];
      if (!columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheek] && !!a.maximaleHypotheek)
        columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheek] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.maximaleHypotheek];
      if (!columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand] && !!a.maximaleHypotheekOnderpand)
        columns[MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand] =
          columnTemplates[MaximaleHypotheekDataGridTemplates.maximaleHypotheekOnderpand];
    });

    return Object.values(columns).filter((c): boolean => !!c) as Column[];
  };

  /* istanbul ignore next */
  const onDataGridSortChange = (sortedData?: any): void => {
    if (!sortedData || !sortedData.length) return;
    const data: any = [];
    sortedData.forEach((obj: any) => {
      data.push(obj);
    });

    setFieldValue("sortedMaximaleHypotheken", data);
  };

  return (
    <div data-testid={"maximale-hypotheek-resultaat-card"}>
      {values.maximaleHypotheken && values.maximaleHypotheken.length > 0 && (
        <MaximaleHypotheekPDF
          sortedData={values.sortedMaximaleHypotheken}
          initial={values.maximaleHypotheken as any}
          values={values}
        />
      )}

      <div className="button-container">
        <FetchDataButton
          onClick={fetchData}
          dataOutDated={values.dataHasChanged || !values.maximaleHypotheken || values.maximaleHypotheken.length < 1}
          invalid={!isValid}
          initialText={getMaximaleHypotheekTextResources("gridInitialText")}
          hasResult={values.maximaleHypotheken && !!values.maximaleHypotheken.length}
        />
      </div>

      {values.maximaleHypotheken && values.maximaleHypotheken.length > 0 && (
        <div data-testid="data-table">
          <DataGrid
            name="maximaleHypotheken"
            sortable={true}
            initialSort={"uitslag"}
            filterable={false}
            loading={false}
            defaultPageSize={values.maximaleHypotheken.length}
            columns={maxHypotheekColumns()}
            sortedCallback={onDataGridSortChange}
            showPagination={false}
          />
          <div className={"d-flex align-items-center justify-content-start my-3"}>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="vink" alt="vink" />
              <span className={"ml-2"}>Toetsing van maatschappij is passend.</span>
            </div>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="uitroepteken" multiColor={true} alt="uitroepteken" />
              <span className={"ml-2"}>Toetsing is niet passend.</span>
            </div>
            <div className={"d-flex align-items-center mx-2"}>
              <Icon name="kruis" alt="kruis" />
              <span className={"ml-2"}>Geen van de toetsingen is passend.</span>
            </div>
          </div>
        </div>
      )}
      {berekend && !values.dataHasChanged && (!values.maximaleHypotheken || values.maximaleHypotheken.length < 1) && (
        <div className="d-flex align-items-center justify-content-center">
          <div>{getMaximaleHypotheekTextResources("gridFetchFout")}</div>
        </div>
      )}
    </div>
  );
};
