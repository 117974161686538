import { Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { Title } from "../shared/components/title/title";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { Aanvrager } from "./aanvrager/aanvrager";
import { asyncMaximaleHypotheekBerekeningSideEffects } from "./infra/determine-async-maximale-hypotheek-side-effects";
import { syncMaximaleHypotheekBerekeningSideEffects } from "./infra/determine-sync-maximale-hypotheek-side-effects";
import {
  MaximaleHypotheekProps,
  maximaleHypotheekSchema,
  MaximaleHypotheekState
} from "./infra/maximale-hypotheek-schema";
import { LeningdelenUitHetVerleden } from "./leningdelen-uit-het-verleden/leningdelen-uit-het-verleden";
import { MaximaleHypotheekResultaat } from "./maximale-hypotheek-resultaat";
import { Toetsrente } from "./toetsrente/toetsrente";
import { Uitgangspunten } from "./uitgangspunten/uitgangspunten";

const MaximaleHypotheekComponent = (
  props: FormikProps<MaximaleHypotheekState> & MaximaleHypotheekProps
): ReactElement => {
  const [berekendState, setBerekendState] = useState(false);
  return (
    <>
      <Title appName="Berekenen" altTitle="Maximale hypotheek" />
      <FormFirstFocus>
        <Form>
          <ISWSideEffects<MaximaleHypotheekState>
            sync={syncMaximaleHypotheekBerekeningSideEffects}
            async={asyncMaximaleHypotheekBerekeningSideEffects}
          />
          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>Maximale hypotheek berekening</h2>
            </div>
          </CardWrapper>

          <PlatformFoutenSamenvatting />

          <CardWrapper className="px-3">
            <Card title="Aanvrager">
              <Aanvrager />
            </Card>
            <Card title="Uitgangspunten">
              <Uitgangspunten />
            </Card>
            <Card title="Toetsrente">
              <Toetsrente />
            </Card>
            <Card title="Leningdelen uit het verleden">
              <LeningdelenUitHetVerleden />
            </Card>
          </CardWrapper>

          <CardWrapper className="px-3">
            <Card
              title={"Maximale hypotheek"}
              className="w-xl-100 w-lg-100 w-md-50 w-50"
              fullscreenTonen
              disableFullScreenButton={!berekendState}
            >
              <MaximaleHypotheekResultaat berekend={berekendState} setBerekend={setBerekendState} />
            </Card>
          </CardWrapper>

          <Debug />
        </Form>
      </FormFirstFocus>
    </>
  );
};

export const MaximaleHypotheek = withAdviesboxFormik<MaximaleHypotheekProps, MaximaleHypotheekState>({
  // Transform outer props into form values

  mapPropsToValues: (e: MaximaleHypotheekProps): MaximaleHypotheekState => {
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== "test") {
      return maximaleHypotheekSchema.default();
    }
    return e;
  },
  validationSchema: maximaleHypotheekSchema,
  mapPropsToErrors: props => ({ aanvrager: { geboortedatumAanvrager1: "nog niet ingevuld" } })
})(MaximaleHypotheekComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  MaximaleHypotheek.displayName = "MaximaleHypotheekBerekening";
