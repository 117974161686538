import {
  BerekenCurrencyInput,
  LabeledJaarMaandInput,
  LabeledBevestigingInput,
  LabeledCurrencyInput,
  LabeledDateInput
} from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { MaximaleHypotheekState } from "../infra/maximale-hypotheek-schema";

type AanvragerProps = {};

export const Aanvrager = (_props: AanvragerProps): ReactElement => {
  const { values } = useFormikContext<MaximaleHypotheekState>();
  return (
    <>
      <LabeledBevestigingInput name="aanvrager.medeAanvrager" caption="Medeaanvrager" />
      {values.aanvrager.medeAanvrager && <h2 className="ml-3">Aanvrager 1</h2>}
      <LabeledDateInput name="aanvrager.geboortedatumAanvrager1" caption="Geboortedatum" verplicht/>
      <LabeledCurrencyInput
        name="aanvrager.toetsinkomenAanvrager1"
        caption="Toetsinkomen"
        appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
        verplicht
      />
      {values.aanvrager.aowJaarMaandAanvrager1 && (
        <LabeledJaarMaandInput name="aanvrager.aowJaarMaandAanvrager1" caption="AOW leeftijd" readonly />
      )}
      <BerekenCurrencyInput
        name="aanvrager.pensioenInkomenAanvrager1"
        caption="Pensioen inkomen"
        appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
      />
      <LabeledCurrencyInput
        name="aanvrager.alimentatieTeBetalenAanvrager1"
        caption="Te betalen alimentatie"
        appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
      />
      <LabeledCurrencyInput
        name="aanvrager.alimentatieTeOntvangenAanvrager1"
        caption="Te ontvangen alimentatie"
        appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
      />
      <LabeledDateInput
        name="aanvrager.alimentatieTeOntvangenEinddatumAanvrager1"
        caption="Einddatum te ontvangen alimentatie"
      />

      {values.aanvrager.medeAanvrager && (
        <>
          <h2 className="ml-3">Aanvrager 2</h2>
          <LabeledDateInput name="aanvrager.geboortedatumAanvrager2" caption="Geboortedatum" />
          <LabeledCurrencyInput
            name="aanvrager.toetsinkomenAanvrager2"
            caption="Toetsinkomen"
            appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
          />
          {values.aanvrager.aowJaarMaandAanvrager2 && (
            <LabeledJaarMaandInput name="aanvrager.aowJaarMaandAanvrager2" caption="AOW leeftijd" readonly />
          )}
          <BerekenCurrencyInput
            name="aanvrager.pensioenInkomenAanvrager2"
            caption="Pensioen inkomen"
            appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
          />
          <LabeledCurrencyInput
            name="aanvrager.alimentatieTeBetalenAanvrager2"
            caption="Alimentatie (te betalen)"
            appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
          />
          <LabeledCurrencyInput
            name="aanvrager.alimentatieTeOntvangenAanvrager2"
            caption="Alimentatie (te ontvangen)"
            appendChildren={<small className={"pt-2 pl-1"}>per jaar</small>}
          />
          <LabeledDateInput
            name="aanvrager.alimentatieTeOntvangenEinddatumAanvrager2"
            caption="Einddatum te ontvangen alimentatie"
          />
        </>
      )}
    </>
  );
};
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Aanvrager.displayName = "Aanvrager";
