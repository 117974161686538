import { MaandlastResultaat } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { MaandlastResultaatType } from "./maandlasten-berekening-schema";

export const mapMaandlastenResult = (apiRes: MaandlastResultaat[] | string): MaandlastResultaatType[] => {
  if (typeof apiRes === "string") return [];
  const mappedApiRes = apiRes.map(
    (r: MaandlastResultaat): MaandlastResultaatType => ({
      aflossing: r.aflossingBedrag,
      rentebedrag: r.renteBedrag,
      brutoLast: r.brutolastBedrag,
      restantHoofdsom: r.restantHoofdsom,
      jaar: r.jaar,
      maand: r.maand
    })
  );

  return mappedApiRes;
};
