/* istanbul ignore file */

import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getMaximaleHypotheekTextResources = createGetTextResources({
  TooltipLeningdelenMeenemen:
    "Selecteer ‘Ja’ als je een of meerdere leningdelen uit het verleden wilt meenemen. Vervolgens voeg je in het specificatiescherm de leningdelen inclusief de kenmerken toe. Deze leningdelen worden meegenomen in de berekening van de maximale hypotheek. ",
  KredietToelastTooltip: `Deze krediet toetslast kan als volgt worden bepaald: 
  - Doorlopend krediet = kredietlimiet * 2% p.m. 
  - Aflopend krediet = kredietsom * 2% p.m. of de werkelijke maandlast (als deze lager is) 
  - Studielening = werkelijke maandlast * TRHK-bruteringsfactor 
  - Werkgeverslening = kredietsom * 2% p.m. of de werkelijke maandlast (als deze lager is) 
  - Private lease = 65% van de totale leasekosten * 2% p.m. 
  - Restschuldlening krediet (met NHG) = Netto kredietmaandlast 
  - Restschuldlening krediet (zonder NHG) = Kredietsom * 2% p.m.`,
  gridInitialText: "Maximale hypotheek berekenen",
  gridFetchFout: "Helaas is er iets misgegaan tijdens het ophalen van de gegevens. Probeer het later nog een keer.",
  energielabelVerplicht: "Energie label is verplicht",
  toetsinkomenMin: "Het minimale toetsinkomen is € 100,-.",
  toetsinkomenMax: "Het maximale toetsinkomen waarmee we rekenen is € 99.999.999,-.",
  toetsinkomenVerplicht: "Vul alsjeblieft een geldige inkomen in.",
});
