import { MaximaleHypotheekInput } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { Organisatie, OrganisatiesOutput } from "../../.generated/licenties/licentiestypes";
import { FieldMap, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { target2field } from "../../shared/utils/target-to-field";
import { organisatieSchema, OrganisatieType } from "./maximale-hypotheek-schema";

export function mapDlTargetToMaximaleHypotheekBerekeningUiField(target: string): UiName | null {
  const map: FieldMap<MaximaleHypotheekInput> = {};

  return target2field(map, target);
}

/* istanbul ignore next */
const mapOrganisatie = createMapToUi(organisatieSchema)
  .with<string>()
  .from<Organisatie>({
    id: (_, c) => c,
    bedrijfsnaam: v => v.naam,
    iban: v => v.iban,
    kvkNummer: v => v.kvKnummer,
    logo: v => v.logo,
    platformId: v => v.platformId
  });

/* istanbul ignore next */
export function mapOrganisatieDlToUi(data: OrganisatiesOutput, id: string): OrganisatieType | null {
  // && data.isValid && data.Contract ? data.Contract[contractId] : null;
  const organisatie = data && data.isValid && data.organisaties ? data.organisaties[id] : null;

  if (organisatie) {
    return mapOrganisatie(id)(organisatie);
  }

  return null;
}
