import { LocalDate } from "@js-joda/core";
import * as Yup from "yup";
import {
  ToetsrenteBerekeningOptions,
  AflossingsVormType,
  UitslagOptions
} from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { jaarMaandInputSchema } from "../../shared/generic-parts/jaar-maand/schema";
import { berekenInputSchema } from "../../shared/types";
import { yupEnum } from "../../shared/utils/yup-enum";
import { getMaximaleHypotheekTextResources } from "./maximale-hypotheek-resources";
import {localDateSchema} from "adviesbox-shared"

export const aanvragerSchema = Yup.object({
  medeAanvrager: Yup.boolean().default(false),
  geboortedatumAanvrager1: localDateSchema.required(),
  geboortedatumAanvrager2: localDateSchema,
  toetsinkomenAanvrager1: Yup.number()
  .min(100, getMaximaleHypotheekTextResources("toetsinkomenMin"))
  .max(99999999, getMaximaleHypotheekTextResources("toetsinkomenMax"))
    .nullable()
    .default(null)
    .required(getMaximaleHypotheekTextResources("toetsinkomenVerplicht")),
  toetsinkomenAanvrager2: Yup.number()
    .nullable()
    .default(null),
  aowLeeftijdAanvrager1: localDateSchema,
  aowJaarMaandAanvrager1: jaarMaandInputSchema.nullable().default(null),
  aowLeeftijdAanvrager2: localDateSchema,
  aowJaarMaandAanvrager2: jaarMaandInputSchema.nullable().default(null),
  pensioenInkomenAanvrager1: berekenInputSchema,

  pensioenInkomenAanvrager2: berekenInputSchema,

  alimentatieTeBetalenAanvrager1: Yup.number()
    .nullable()
    .default(null),
  alimentatieTeBetalenAanvrager2: Yup.number()
    .nullable()
    .default(null),
  alimentatieTeOntvangenAanvrager1: Yup.number()
    .nullable()
    .default(null),
  alimentatieTeOntvangenAanvrager2: Yup.number()
    .nullable()
    .default(null),
  alimentatieTeOntvangenEinddatumAanvrager1: jaarMaandInputSchema.nullable().default(null),
  alimentatieTeOntvangenEinddatumAanvrager2: jaarMaandInputSchema.nullable().default(null)
});
export type AanvragerType = Yup.InferType<typeof aanvragerSchema>;

export const uitgangspuntenSchema = Yup.object({
  nhg: Yup.boolean()
    .nullable()
    .default(false),
  nhgHypotheeknorm: Yup.string().default(
    LocalDate.now()
      .year()
      .toString()
  ),
  looptijd: Yup.number().default(30),
  marktwaardeBedrag: Yup.number()
    .nullable()
    .default(null),
  gewensteHypotheekBedrag: Yup.number()
    .nullable()
    .default(null),
  box3gedeelteBedrag: Yup.number()
    .nullable()
    .default(null),
  erfpachtcanonBedrag: Yup.number()
    .nullable()
    .default(null),
  energielabel: Yup.string()
    .nullable()
    .default(null)
    .required(getMaximaleHypotheekTextResources("energielabelVerplicht"))
});

export type UitgangspuntenType = Yup.InferType<typeof uitgangspuntenSchema>;

export const toetsrenteSchema = Yup.object({
  berekeningOpBasis: yupEnum(ToetsrenteBerekeningOptions).default(ToetsrenteBerekeningOptions.GewensteRentevastperiode),
  gewensteRentevastperiode: Yup.number()
    .default(10)
    .nullable(),
  toetsrente: Yup.number()
    .nullable()
    .default(null),
  vrijvermogenBedrag: Yup.number()
    .nullable()
    .default(null),
  kredietToetslastBedrag: Yup.number()
    .nullable()
    .default(null)
});

export const modalLeningdelenSchema = Yup.object({
  hypotheekvorm: yupEnum(AflossingsVormType)
    .default(AflossingsVormType.Geen)
    .meta({ testdata: AflossingsVormType }),
  opgebouwdeWaarde: Yup.number()
    .nullable()
    .default(null),
  hypotheekbedrag: Yup.number()
    .nullable()
    .default(null),
  looptijd: jaarMaandInputSchema.nullable().default(null),
  editable: Yup.boolean().default(true),
  deelBox3: Yup.number()
    .nullable()
    .default(null)
});

export type ToetsrenteType = Yup.InferType<typeof toetsrenteSchema>;

export const leningdelenUitHetVerledenSchema = Yup.object({
  meenemen: Yup.bool().default(false),
  aantal: Yup.number().default(0),
  leningdelen: Yup.array(modalLeningdelenSchema).default([])
});

export const organisatieSchema = Yup.object({
  id: Yup.string()
    .nullable()
    .default(null),
  logo: Yup.string()
    .nullable()
    .default(null),
  platformId: Yup.string()
    .nullable()
    .default(""),
  bedrijfsnaam: Yup.string().default(""),
  kvkNummer: Yup.string()
    .nullable()
    .default(""),
  iban: Yup.string()
    .nullable()
    .default("")
});

export type OrganisatieType = Yup.InferType<typeof organisatieSchema>;

export const maximaleHypotheekResultaatSchema = Yup.object({
  uitslag: yupEnum(UitslagOptions),
  looptijd: Yup.number(),
  toetsrente: Yup.number(),
  productnaam: Yup.string(),
  extraAflossing: Yup.number(),
  maximaleHypotheek: Yup.number(),
  maximaleHypotheekOnderpand: Yup.number()
});

export const maximaleHypotheekSchema = Yup.object({
  aanvrager: aanvragerSchema,
  uitgangspunten: uitgangspuntenSchema,
  toetsrente: toetsrenteSchema,
  leningdelenUitHetVerleden: leningdelenUitHetVerledenSchema,
  maximaleHypotheken: Yup.array(maximaleHypotheekResultaatSchema).default([]),
  sortedMaximaleHypotheken: Yup.array(maximaleHypotheekResultaatSchema).default([]),
  dataHasChanged: Yup.boolean().default(false),
  organisatie: organisatieSchema.nullable().default(null)
});

export type LeningdelenUitHetVerledenType = Yup.InferType<typeof leningdelenUitHetVerledenSchema>;
export type MaximaleHypotheekState = Yup.InferType<typeof maximaleHypotheekSchema>;
export type MaximaleHypotheekProps = Yup.InferType<typeof maximaleHypotheekSchema>;
export type MaximaleHypotheekResultaatType = Yup.InferType<typeof maximaleHypotheekResultaatSchema>;
export type ModalLeningdeelType = Yup.InferType<typeof modalLeningdelenSchema>;
export enum MaximaleHypotheekDataGridTemplates {
  uitslag,
  productnaam,
  toetsrente,
  extraAflossing,
  looptijd,
  maximaleHypotheek,
  maximaleHypotheekOnderpand,
  inkomstenVerklaring1,
  inkomstenVerklaring2
}
