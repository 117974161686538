/* istanbul ignore file */
import { MenuWrapper } from "adviesbox-shared";
import React, { ReactElement } from "react";
import brandLogoIcon from "../assets/new-brand-logo.svg";
import brandCollapsedLogoIcon from "../assets/new-collapsed-brand-logo.svg";
import { menuConfig } from "./menu-config";

type NavigationListProps = {
  collapsed: boolean;
  collapse(): void;
};

const NavigationList = ({ collapsed, collapse }: NavigationListProps): ReactElement => {
  return (
    <MenuWrapper
      brandLogoIcon={brandLogoIcon}
      brandCollapsedLogoIcon={brandCollapsedLogoIcon}
      collapse={collapse}
      collapsed={collapsed}
      hasMenuInfo={true}
      loading={false}
      menuConfig={menuConfig()}
      appName={"berekeningen"}
    />
  );
};

NavigationList.displayName = "NavigationList";

export default NavigationList;
