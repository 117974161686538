import { AuthContext } from "adviesbox-shared";
import React, { ReactElement } from "react";
import AuthenticatedBerekeningenApp from "./AuthenticatedBerekeningenApp";
import UnauthenticatedBerekeningenApp from "./UnauthenticatedBerekeningenApp";

const BerekeningenApp = (): ReactElement => {
  const { user } = React.useContext(AuthContext);

  if (user) {
    return <AuthenticatedBerekeningenApp />;
  }

  return <UnauthenticatedBerekeningenApp />;
};

BerekeningenApp.displayName = "BerekeningenApp";

export default BerekeningenApp;
