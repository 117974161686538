/* istanbul ignore file */

import { DataGrid, useAdviesboxDataRepository, useRequestInit, FetchDataButton } from "adviesbox-shared";
import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useReducer } from "react";
import { MaandlastenInput, MaandlastenOutput } from "../.generated/berekeningen-forms/berekeningen-formstypes";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { FieldMap, UiName } from "../shared/types";
import { target2field } from "../shared/utils/target-to-field";
import { syncMaandlastenResultaatSideEffects } from "./determine-sync-maandlasten-resultaat-side-effects";
import { maandlastResultaatColumns } from "./infra/maandlasten-berekening-columns";
import { getMaandlastenBerekeningTextResources } from "./infra/maandlasten-berekening-resources";
import {
  IndexIsExtendedType,
  MaandlastenBerekeningState,
  MaandlastResultaatType,
  YearIsExtendedType
} from "./infra/maandlasten-berekening-schema";
import { mapMaandlastenResult } from "./infra/map-maandlasten-berekening-dl-to-ui";
import { mapMaandlastenBerekeningUiToDl } from "./infra/map-maandlasten-berekening-ui-to-dl";
import { MaandlastenBerekeningPDF } from "./maandlasten-berekening-pdf/maandlasten-berekening-pdf";

export type MaximaleHypotheekDataGridProps = {
  setBerekend: React.Dispatch<React.SetStateAction<boolean>>;
  berekend: boolean;
};

export const distinctTotals = (
  results: MaandlastResultaatType[],
  extendedByIndex: IndexIsExtendedType | null
): YearIsExtendedType[] => {
  if (!results.length) return [];
  const uniqueSet = new Set(results.map(v => v.jaar ?? 0));
  const uniqueJaren = Array.from(uniqueSet).sort();
  const distinctTotals: YearIsExtendedType[] = uniqueJaren.map((jaar, i) => {
    let ttlAflossing = 0;
    let ttlRente = 0;
    let ttlBrutolast = 0;
    const decemberRestantHoofdsom = results.find(v => v.jaar === jaar && v.maand === 12)?.restantHoofdsom ?? 0;
    const extendedIndexes = extendedByIndex && Object.keys(extendedByIndex).filter(v => !!extendedByIndex[v]);
    const extended = extendedIndexes?.includes(i.toString());

    results.forEach(v => {
      if (v.jaar === jaar) {
        ttlAflossing += v.aflossing ?? 0;
        ttlRente += v.rentebedrag ?? 0;
        ttlBrutolast += v.brutoLast ?? 0;
      }
    });

    return { ttlAflossing, ttlRente, ttlBrutolast, decemberRestantHoofdsom, extended, jaar };
  });

  return distinctTotals;
};

export function mapDlTargetToMaandlastenBerekeningUiField(target: string): UiName | null {
  const map: FieldMap<MaandlastenInput> = {};

  return target2field(map, target);
}

export const MaandlastenBerekenenResultaat = ({
  berekend,
  setBerekend
}: MaximaleHypotheekDataGridProps): ReactElement => {
  const { values, isValid, setFieldValue } = useFormikContext<MaandlastenBerekeningState>();
  const { settings } = useRequestInit({
    extraHeaders: {
      "Content-Type": "application/json"
    }
  });

  const url = `${settings.berekeningenFormsOrigin}/Maandlasten`;
  const { fetchData } = useAdviesboxDataRepository<MaandlastenOutput, MaandlastenBerekeningState>(url, {
    method: "POST",
    getDataId: () => "",
    mapDlToUi: (_, res) => {
      const mappedResults = mapMaandlastenResult(Object.values(res?.maandlastResultaten ?? []));
      const extendedByYear = distinctTotals(mappedResults, values.extendedByIndex);

      const newValues: MaandlastenBerekeningState = {
        ...values,
        dataHasChanged: false,
        maandlastResultaten: mappedResults,
        extendedByYear
      };
      setBerekend(true);

      return newValues;
    },
    mapUiToDl: mapMaandlastenBerekeningUiToDl,
    mapTargetToUiField: mapDlTargetToMaandlastenBerekeningUiField
  });

  const [expanded, setExpanded] = useReducer((_: any, action: any) => action, {});

  useEffect(() => {
    if (expanded) {
      setFieldValue("extendedByIndex", expanded);
    }
  }, [expanded, setFieldValue]);

  const onDataGridSortChange = (sortedData?: any): void => {
    if (!sortedData || !sortedData.length) return;
    const data: any = [];
    sortedData.forEach((obj: any) => {
      data.push(obj);
    });

    setFieldValue("sortedMaandlasten", data);
  };

  return (
    <div>
      <ISWSideEffects<MaandlastenBerekeningState> sync={syncMaandlastenResultaatSideEffects()} />
      {values.maandlastResultaten && values.maandlastResultaten.length > 0 && (
        <MaandlastenBerekeningPDF
          sortedData={values.sortedMaandlasten}
          initial={values.maandlastResultaten}
          values={values}
        />
      )}

      <div className="button-container">
        <FetchDataButton
          onClick={fetchData}
          dataOutDated={values.dataHasChanged || values.maandlastResultaten.length < 1}
          invalid={!isValid}
          initialText={getMaandlastenBerekeningTextResources("gridInitialText")}
          hasResult={values.maandlastResultaten && !!values.maandlastResultaten.length}
        />
      </div>

      {values.maandlastResultaten && values.maandlastResultaten.length > 0 && (
        <div data-testid="data-table">
          <DataGrid
            name="maandlastResultaten"
            sortable={false} //pivot tables can't be sorted at the moment
            filterable={false}
            loading={false}
            columns={maandlastResultaatColumns()}
            showPagination={false}
            resizable={false}
            defaultPageSize={values.uitgangspunten.looptijd?.jaren ?? 30}
            pivotBy={["jaar"]}
            sortedCallback={onDataGridSortChange}
            collapseOnDataChange={false}
            onExpandedChange={newExpanded => {
              setExpanded(newExpanded);
            }}
          />
        </div>
      )}
      {berekend && !values.dataHasChanged && (!values.maandlastResultaten || values.maandlastResultaten.length < 1) && (
        <div className="d-flex align-items-center justify-content-center">
          <div>{getMaandlastenBerekeningTextResources("gridFetchFout")}</div>
        </div>
      )}
    </div>
  );
};
