import { createSpanWithId } from "adviesbox-shared";
import _ from "lodash";
import moment from "moment";
import React, { ReactElement } from "react";
import { CellInfo, Column } from "react-table-6";
import { bedragFormat } from "../../shared/utils/currency";

export const maandlastResultaatColumns = (): Column[] => {
  return [
    {
      Header: "Jaar",
      id: "jaar",
      accessor: "jaar",
      // eslint-disable-next-line react/display-name
      PivotValue: (c: CellInfo): ReactElement => <span>{c.row.jaar}</span>
    },
    {
      Header: "Maand",
      id: "maand",
      accessor: "maand",
      Cell: (c): ReactElement => {
        return createSpanWithId(c.index, 3, moment.months(c.original?.maand - 1 || 0));
      },
      Aggregated: row => ""
    },
    {
      Header: "Aflossing",
      id: "aflossingsbedrag",
      accessor: "aflossing",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original?.aflossing || "")),
      aggregate: (values: (number | null)[]) => _.sum(values),
      // eslint-disable-next-line react/display-name
      Aggregated: (cell): ReactElement => {
        return <span>{bedragFormat(cell.value)}</span>;
      }
    },
    {
      Header: "Rente",
      id: "rente",
      accessor: "rentebedrag",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original?.rentebedrag || "")),
      aggregate: (values: (number | null)[]) => _.sum(values),
      // eslint-disable-next-line react/display-name
      Aggregated: (cell): ReactElement => {
        return <span>{bedragFormat(cell.value)}</span>;
      }
    },
    {
      Header: "Bruto last",
      id: "brutoLast",
      accessor: "brutoLast",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original?.brutoLast || "")),
      aggregate: (values: (number | null)[]) => _.sum(values),
      // eslint-disable-next-line react/display-name
      Aggregated: (cell): ReactElement => {
        return <span>{bedragFormat(cell.value)}</span>;
      }
    },
    {
      Header: "Restant hoofdsom",
      accessor: "restantHoofdsom",
      Cell: (c): ReactElement => createSpanWithId(c.index, 3, bedragFormat(c.original?.restantHoofdsom || "")),
      aggregate: (values: (number | null)[]) => values[values.length - 1],
      // eslint-disable-next-line react/display-name
      Aggregated: (cell): ReactElement => {
        return <span>{bedragFormat(cell.value)}</span>;
      }
    }
  ];
};
