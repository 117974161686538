/* istanbul ignore file */
import { createGetTextResources } from "../../shared/utils/text-resources-algemeen";

export const getMaandlastenBerekeningTextResources = createGetTextResources({
  gridInitialText: "Maandlasten berekenen",
  gridFetchFout: "Helaas is er iets misgegaan tijdens het ophalen van de gegevens. Probeer het later nog een keer.",
  hypotheekBedragVerplicht: "Vul alsjeblieft een geldig hypotheekbedrag in.",
  ingangsdatumVerplicht: "Vul alsjeblieft een geldige ingangsdatum in.",
  rentePercentage: "Vul alsjeblieft een rentepercentage in.",
  renteMinimaal: "De minimale rente is 0.01%.",
  renteMaximaal: "De rente mag maximaal 20% bedragen.",
  hypotheekbedragMin: "Het minimale hypotheekbedrag is € 1.000,-.",
  hypotheekbedragMax: "Het maximale hypotheekbedrag waarmee we rekenen is € 99.999.999,-.",
});
