import { Card, CardWrapper, FormFirstFocus, PlatformFoutenSamenvatting } from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { Debug } from "../shared/components/formik/Debug";
import { ISWSideEffects } from "../shared/components/isw-side-effects/isw-side-effects";
import { Title } from "../shared/components/title/title";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { syncMaandlastenBerekeningSideEffects } from "./infra/determine-sync-maandlasten-berekening-side-effects";
import {
  MaandlastenBerekeningProps,
  maandlastenBerekeningSchema,
  MaandlastenBerekeningState
} from "./infra/maandlasten-berekening-schema";
import { MaandlastenBerekenenResultaat } from "./maandlasten-berekenen-resultaat";
import { Uitgangspunten } from "./uitgangspunten/uitgangspunten";

const MaandlastenBerekeningComponent = (
  props: FormikProps<MaandlastenBerekeningState> & MaandlastenBerekeningProps
): ReactElement => {
  const [berekendState, setBerekendState] = useState(false);
  const { values } = useFormikContext<MaandlastenBerekeningState>();

  return (
    <>
      <Title appName="Berekenen" altTitle="Maandlasten" />
      <FormFirstFocus>
        <Form>
          <ISWSideEffects<MaandlastenBerekeningState> sync={syncMaandlastenBerekeningSideEffects} />

          <CardWrapper className="px-3">
            <div className="text-container">
              <h2>Maandlasten berekenen</h2>
            </div>
          </CardWrapper>

          <PlatformFoutenSamenvatting />

          <CardWrapper className="px-3">
            <Card title="Uitgangspunten">
              <Uitgangspunten />
            </Card>
          </CardWrapper>

          <CardWrapper className="px-3">
            <Card
              title={"Maandlasten"}
              className="w-xl-100 w-lg-100 w-md-50 w-50"
              fullscreenTonen={values.maandlastResultaten.length > 1}
              disableFullScreenButton={!berekendState}
            >
              <MaandlastenBerekenenResultaat setBerekend={setBerekendState} berekend={berekendState} />
            </Card>
          </CardWrapper>

          <Debug />
        </Form>
      </FormFirstFocus>
    </>
  );
};

export const MaandlastenBerekening = withAdviesboxFormik<MaandlastenBerekeningProps, MaandlastenBerekeningState>({
  // Transform outer props into form values
  mapPropsToValues: (e: MaandlastenBerekeningProps): MaandlastenBerekeningState => {
    return { ...maandlastenBerekeningSchema.default() };
  },
  validationSchema: maandlastenBerekeningSchema,
  mapPropsToErrors: props => ({
    uitgangspunten: { hypotheekVorm: "nog niet ingevuld", rentePercentage: "nog niet ingevuld" }
  })
})(MaandlastenBerekeningComponent);
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production")
  MaandlastenBerekening.displayName = "MaandlastenBerekening";
