/* istanbul ignore file */
import { beschikbareHypotheekProducten } from "adviesbox-shared";
import {
  MaximaleHypotheekResultaat,
  UitslagOptions
} from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { HypotheekvormBasis } from "../../.generated/producten/productentypes";
import { partijFilter } from "../../shared/utils/partij-filter";
import { MaximaleHypotheekResultaatType } from "./maximale-hypotheek-schema";

export const mapMaximaleHypothekenResult = (res: MaximaleHypotheekResultaat[]): MaximaleHypotheekResultaatType[] => {
  const mappedRes = res
    .filter(c =>
      partijFilter(
        { maatschappijCode: c.maatschappijCode, code: c.productCode } as HypotheekvormBasis,
        beschikbareHypotheekProducten
      )
    )
    .map(
      (c: MaximaleHypotheekResultaat): MaximaleHypotheekResultaatType => ({
        looptijd: (!!c.looptijd && c.looptijd / 12) || 0,
        maximaleHypotheek: c.maximaleHypotheek || 0,
        maximaleHypotheekOnderpand: c.maximaleHypotheekOnderpand || 0,
        productnaam: c.productnaam || "",
        toetsrente: c.toetsrente || 0,
        uitslag: c.uitslag || UitslagOptions.Wit,
        extraAflossing: c.extraAflossing || 0
      })
    );
  return mappedRes;
};
