import { Afbeelding, Icon, useRequestInit } from "adviesbox-shared";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import useAbortableFetch from "use-abortable-fetch";
import AdviseurNaam from "../../auth/AdviseurNaam";
import { OrganisatiesOutput } from "../../.generated/licenties/licentiestypes";
import { bedragFormat } from "../../shared/utils/currency";
import { getFormattedDate } from "../../shared/utils/dates";
import { decimalFormat } from "../../shared/utils/decimal-format";
import UserDetailsContext from "../../user-details/user-details-context";
import {
  AanvragerType,
  LeningdelenUitHetVerledenType,
  MaximaleHypotheekResultaatType,
  MaximaleHypotheekState,
  ToetsrenteType,
  UitgangspuntenType
} from "../infra/maximale-hypotheek-schema";
import classes from "../maximale-hypotheek.module.scss";
import { getCurrentDateFormatted, printPDF } from "./maximale-hypotheek-pdf-helpers/maximale-hypotheek-pdf-helpers";

/* istanbul ignore file */

type MaximaleHypotheekPDFProps = {
  values: MaximaleHypotheekState;
  sortedData: MaximaleHypotheekResultaatType[];
  initial: MaximaleHypotheekResultaatType;
};

export const MaximaleHypotheekPDF = ({ values, sortedData, initial }: MaximaleHypotheekPDFProps): ReactElement => {
  const [data, setData] = useState<any>([]);
  const { settings, requestInit } = useRequestInit();
  const { userDetails } = useContext(UserDetailsContext);

  const organisatieId = userDetails.organisatieId ? userDetails.organisatieId : null;
  const organisatieUrl = organisatieId ? `${settings.licentiesOrigin}/Organisaties/${organisatieId}` : null;
  const organisatie = useAbortableFetch<OrganisatiesOutput>(organisatieUrl, requestInit);
  useEffect(() => {
    if (sortedData.length > 0) {
      setData(sortedData);
      return;
    }

    setData(initial);
  }, [sortedData, initial]);

  const organisatieLogo =
    organisatie.data && typeof organisatie.data !== "string" && organisatie.data.organisaties && organisatieId
      ? organisatie.data.organisaties[organisatieId].logo
      : "";

  const maximaleHypotheken = [...data];
  const amountOfLinesFirstPage = 40;
  const amountOfLinesPerPage = 55;
  const amountOflinesTotal = maximaleHypotheken.length;
  let amountOfpages = Math.ceil((amountOflinesTotal - amountOfLinesFirstPage) / amountOfLinesPerPage + 1);

  const bedrijfslogo = new Image();
  bedrijfslogo.src = `data:image/png;base64,${organisatieLogo}`;

  const leningdelenTotaal = values.leningdelenUitHetVerleden?.leningdelen
    ?.map(leningdeel => leningdeel.hypotheekbedrag || 0)
    .reduce((a, b) => a + b, 0);
  const totaleLening =
    leningdelenTotaal +
    (values.uitgangspunten
      ? values.uitgangspunten.gewensteHypotheekBedrag
        ? values.uitgangspunten.gewensteHypotheekBedrag
        : 0
      : 0);

  const renderHeader = (aanvrager: AanvragerType): ReactElement => {
    return (
      <>
        <div className={classes.pdf_header_container}>
          <h1 className={classes.pdf_header}>Maximale Hypotheek</h1>
          <div className={classes.pdf_header}>
            <Afbeelding
              name="organisatie.logo"
              alt="bedrijfslogo"
              location={bedrijfslogo.src}
              style={{ maxHeight: 60 }}
            />
          </div>
        </div>
        <table className={classes.pdf_table}>
          <thead>
            <tr>
              <th className={classes.pdf_table_header}></th>
              <th className={`${classes.pdf_table_header_center}`}>Aanvrager 1</th>
              <th className={classes.pdf_table_header}>{aanvrager.medeAanvrager ? "Aanvrager 2" : ""}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.pdf_table_data_info}>Geboortedatum</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {aanvrager.geboortedatumAanvrager1 ? getFormattedDate(aanvrager.geboortedatumAanvrager1) : ""}
              </td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {aanvrager.medeAanvrager && aanvrager.geboortedatumAanvrager2
                  ? getFormattedDate(aanvrager.geboortedatumAanvrager2)
                  : ""}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Toetsinkomen</td>
              <td className={`${classes.pdf_table_data}`}>
                {aanvrager.toetsinkomenAanvrager1 ? bedragFormat(aanvrager.toetsinkomenAanvrager1) : bedragFormat(0)}
              </td>
              <td className={`${classes.pdf_table_data}`}>
                {aanvrager.medeAanvrager && aanvrager.toetsinkomenAanvrager2
                  ? bedragFormat(aanvrager.toetsinkomenAanvrager2)
                  : ""}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Pensioensinkomen</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {aanvrager.pensioenInkomenAanvrager1.berekenen
                  ? aanvrager.pensioenInkomenAanvrager1?.berekendBedrag
                    ? bedragFormat(aanvrager.pensioenInkomenAanvrager1.berekendBedrag)
                    : bedragFormat(0)
                  : aanvrager.pensioenInkomenAanvrager1?.bedrag
                  ? bedragFormat(aanvrager.pensioenInkomenAanvrager1.bedrag)
                  : bedragFormat(0)}
              </td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {aanvrager.pensioenInkomenAanvrager2 && aanvrager.pensioenInkomenAanvrager2.berekenen
                  ? aanvrager.pensioenInkomenAanvrager2?.berekendBedrag
                    ? bedragFormat(aanvrager.pensioenInkomenAanvrager2.berekendBedrag)
                    : bedragFormat(0)
                  : aanvrager.pensioenInkomenAanvrager2?.bedrag
                  ? bedragFormat(aanvrager.pensioenInkomenAanvrager2.bedrag)
                  : ""}
              </td>
            </tr>
            {(aanvrager.aowJaarMaandAanvrager1?.jaren || aanvrager.aowJaarMaandAanvrager2?.jaren) && (
              <tr>
                <td className={classes.pdf_table_data_info}>AOW leeftijd</td>
                <td className={`${classes.pdf_table_data}`}>
                  {aanvrager.aowJaarMaandAanvrager1 ? `${aanvrager.aowJaarMaandAanvrager1?.jaren ?? 0} jaar` : ""}
                </td>
                <td className={`${classes.pdf_table_data}`}>
                  {aanvrager.aowJaarMaandAanvrager2 ? `${aanvrager.aowJaarMaandAanvrager2?.jaren ?? 0} jaar` : ""}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  };

  const renderFooter = (): ReactElement => {
    return (
      <>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.pdf_footer}>
          <div className={classes.pdf_footer_header}>
            <span>
              Deze bedragen zijn slechts een indicatie van wat werkelijk mogelijk is. Controle van de acceptatienormen
              van de gekozen maatschappij dient te geschieden op het tabblad &apos;acceptatie&apos;. Definitieve
              acceptatie geschiedt altijd door de gekozen maatschappij zelf.
            </span>
          </div>
          <div className={classes.pdf_footer_sub}>
            <span>
              Overzicht verzorgd door{" "}
              <span>
                <AdviseurNaam />{" "}
              </span>
              van{" "}
              {organisatie.data &&
              typeof organisatie.data !== "string" &&
              organisatie.data.organisaties &&
              organisatieId
                ? organisatie.data.organisaties[organisatieId].naam
                : ""}{" "}
              op {getCurrentDateFormatted()}.
            </span>
            <span className={classes.pdf_footer_sub_rights}>
              Aan dit overzicht kunnen geen rechten worden ontleend.
            </span>
          </div>
        </div>
      </>
    );
  };

  const renderUitgangspunten = (
    uitgangspunten: UitgangspuntenType,
    toetsrente: ToetsrenteType,
    leningdelenUitHetVerleden: LeningdelenUitHetVerledenType,
    onlyUitgangsPunten: boolean
  ): ReactElement => {
    return (
      <>
        <table className={onlyUitgangsPunten ? classes.pdf_padding : ""}>
          <thead>
            <tr>
              <td className={classes.pdf_table_uitgangspunten_header_left}>Uitgangspunten berekening</td>
              <td className={classes.pdf_table_uitgangspunten_header_right}></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.pdf_table_data_info}>Marktwaarde</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {uitgangspunten.marktwaardeBedrag ? bedragFormat(uitgangspunten.marktwaardeBedrag) : bedragFormat(0)}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Gewenste hypotheek</td>
              <td className={`${classes.pdf_table_data}`}>
                {uitgangspunten.gewensteHypotheekBedrag
                  ? bedragFormat(uitgangspunten.gewensteHypotheekBedrag)
                  : bedragFormat(0)}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Totale lening</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {leningdelenUitHetVerleden?.leningdelen ? bedragFormat(totaleLening) : bedragFormat(0)}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Looptijd hypotheek</td>
              <td className={`${classes.pdf_table_data}`}>{uitgangspunten.looptijd} jaar</td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Gespecificeerde rentevastperiode</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {toetsrente.gewensteRentevastperiode} jaar
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Vrij vermogen</td>
              <td className={`${classes.pdf_table_data}`}>
                {toetsrente.vrijvermogenBedrag ? bedragFormat(toetsrente.vrijvermogenBedrag) : bedragFormat(0)}
              </td>
            </tr>
            <tr>
              <td className={classes.pdf_table_data_info}>Maandlast kredieten</td>
              <td className={`${classes.pdf_table_data}`} style={{ backgroundColor: "#f0f0f0" }}>
                {toetsrente.kredietToetslastBedrag ? bedragFormat(toetsrente.kredietToetslastBedrag) : bedragFormat(0)}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  const renderPage = (
    firstRender: boolean,
    index: number,
    lastPage: boolean,
    renderOnlyUitgangspunten: boolean,
    hypotheken?: undefined | MaximaleHypotheekResultaatType[],
    uitgangspunten?: UitgangspuntenType
  ): ReactElement => {
    return (
      <div
        className={classes.pdf_hide}
        id={`print-container-${index}`}
        key={`print-container-${index}-key`}
        style={{ maxWidth: "30.3cm", minWidth: "30.3cm", display: "block" }}
      >
        <div className={classes.pdf_page} style={{ backgroundColor: "white" }}>
          {firstRender && !renderOnlyUitgangspunten && renderHeader(values?.aanvrager)}
          {!renderOnlyUitgangspunten && hypotheken && (
            <table className={`${classes.pdf_table} ${classes.pdf_table_dataTable}`}>
              <thead>
                <tr>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_first}`}>
                    Maatschappij
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_second}`}>
                    {uitgangspunten?.nhg ? "NHG%" : "Toetsrente"}
                  </th>

                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_third}`}>
                    Looptijd
                  </th>

                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_fourth}`}>
                    {uitgangspunten?.nhg ? "NHG Toetsing" : "Maximale hyp."}
                  </th>
                  <th className={`${classes.pdf_table_data_header} ${classes.pdf_table_data_header_fifth}`}>
                    {uitgangspunten?.nhg ? "NHG Onderpand/lening" : "Onderpand"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {hypotheken.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{data.productnaam}</td>
                      <td className={classes.pdf_table_right_align}>
                        {data.toetsrente ? `${decimalFormat(data.toetsrente, 3)}%` : ""}
                      </td>
                      <td className={classes.pdf_table_right_align}>{data.looptijd ? `${data.looptijd} jr` : ""}</td>
                      <td className={classes.pdf_table_right_align}>
                        {data.maximaleHypotheek ? bedragFormat(data.maximaleHypotheek) : bedragFormat(0)}
                      </td>
                      <td className={classes.pdf_table_right_align}>
                        {data.maximaleHypotheekOnderpand
                          ? bedragFormat(data.maximaleHypotheekOnderpand)
                          : bedragFormat(0)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {(lastPage || renderOnlyUitgangspunten) &&
            renderUitgangspunten(
              values?.uitgangspunten,
              values?.toetsrente,
              values?.leningdelenUitHetVerleden,
              renderOnlyUitgangspunten
            )}
          {renderFooter()}
        </div>
      </div>
    );
  };

  const renderPages = (uitgangspunten: UitgangspuntenType): ReactElement => {
    let lastPage = false;
    let uitgangsPuntenFitsOnLastPage = false;
    const counter = amountOfpages;
    const pages: ReactElement[] = [];
    if (maximaleHypotheken.length === 0) return <></>;
    for (let i = 1; i <= counter; i++) {
      const startFrom = 0;
      const until = (): number => {
        if (i === 1) {
          return amountOfLinesFirstPage;
        }

        return amountOfLinesPerPage;
      };

      const sliced = maximaleHypotheken.splice(startFrom, until());
      const amountOfLinesOnpage = sliced.length;
      const EnoughSpaceForUitgangspunten = (): boolean => {
        const check = amountOfLinesOnpage + 13;
        if (i === 1 && check >= amountOfLinesFirstPage) return false;
        if (i !== 1 && check >= amountOfLinesPerPage) return false;
        return true;
      };

      if (amountOfpages === i) {
        lastPage = true;
        if (EnoughSpaceForUitgangspunten()) uitgangsPuntenFitsOnLastPage = true;
      }

      if (i > amountOfpages) break;

      const page = renderPage(i === 1, i, uitgangsPuntenFitsOnLastPage, false, sliced, uitgangspunten);
      pages.push(page);

      if (lastPage && !uitgangsPuntenFitsOnLastPage) {
        amountOfpages = amountOfpages + 1;
        const uitgangspuntenIndex = i + 1;
        const uitgangsPunten = renderPage(
          uitgangspuntenIndex === 1,
          uitgangspuntenIndex,
          uitgangsPuntenFitsOnLastPage,
          true,
          sliced,
          uitgangspunten
        );
        pages.push(uitgangsPunten);
      }
    }

    return <>{pages.map(page => page)}</>;
  };

  return (
    <>
      {renderPages(values.uitgangspunten)}
      <Button onClick={async () => await printPDF(amountOfpages, "download")}>
        <Icon name="download" alt="download" multiColor={true} />
      </Button>
    </>
  );
};
