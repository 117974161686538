import {
  AdviesBoxFormik,
  createSpanWithId,
  DataGrid,
  LabeledJaarMaandInput,
  LabeledCurrencyInput,
  LabeledSelectInput
} from "adviesbox-shared";
import { FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import { Column } from "react-table-6";
import { AflossingsVormType } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import Modal from "../../shared/components/modal/Modal";
import { bedragFormat } from "../../shared/utils/currency";
import { enum2options } from "../../shared/utils/enum-to-options";
import {
  leningdelenUitHetVerledenSchema,
  LeningdelenUitHetVerledenType,
  ModalLeningdeelType,
  modalLeningdelenSchema
} from "../infra/maximale-hypotheek-schema";

type ModalLeningdelenProps = {
  onSave?: (data: ModalLeningdeelType[]) => void;
  closeModal?: () => void;
  data: LeningdelenUitHetVerledenType;
};

type LeningdelenProps = {
  setValues: (data: LeningdelenUitHetVerledenType) => void;
  values: LeningdelenUitHetVerledenType;
};

const AflossingsVormTypeMapper: Record<AflossingsVormType, AflossingsVormType> = {
  Geen: AflossingsVormType.Geen,
  Aflosvrij: AflossingsVormType.Aflosvrij,
  Annuïteit: AflossingsVormType.Annuïteit,
  Spaarrekening: AflossingsVormType.Spaarrekening,
  Belegging: AflossingsVormType.Belegging,
  Hybride: AflossingsVormType.Hybride,
  Krediet: AflossingsVormType.Krediet,
  Levensverzekering: AflossingsVormType.Levensverzekering,
  Lineair: AflossingsVormType.Lineair,
  AnnuïteitUitgesteld: AflossingsVormType.AnnuïteitUitgesteld,
  AnnuïteitBlok: AflossingsVormType.AnnuïteitBlok,
  Spaar: AflossingsVormType.Spaar,
  UnitLinked: AflossingsVormType.UnitLinked,
  KredietNoPay: AflossingsVormType.KredietNoPay
};

const MeeTeNemenLeningdelenModal = ({ onSave, closeModal, data }: ModalLeningdelenProps): ReactElement => {
  const selectedState = useState(0);
  const [selected] = selectedState;
  const addLeningdeel = (): ModalLeningdeelType => modalLeningdelenSchema.default();

  const leningdelenColumns: Column[] = [
    {
      Header: "Hypotheekbedrag",
      id: "hypotheekbedrag",
      Cell: (c): ReactElement => createSpanWithId(c.index, 0, bedragFormat(c.original.hypotheekbedrag))
    },
    {
      Header: "Hypotheekvorm",
      id: "hypotheekvorm",
      Cell: (c): ReactElement => createSpanWithId(c.index, 1, c.original.hypotheekvorm)
    },
    {
      Header: "Opgeb. waarde",
      id: "opgebouwdeWaarde",
      Cell: (c): ReactElement => createSpanWithId(c.index, 2, bedragFormat(c.original.opgebouwdeWaarde))
    },
    {
      id: "looptijd",
      Header: "Rest. looptijd",
      Cell: (c): ReactElement =>
        createSpanWithId(
          c.index,
          3,
          c.original.looptijd && (c.original.looptijd.jaren || c.original.looptijd.maanden)
            ? `${c.original.looptijd.jaren ? `${c.original.looptijd.jaren} jr` : ""} ${c.original.looptijd.maanden
              ? `${c.original.looptijd.maanden} mnd` 
              : ""
            }`
            : ""
        )
    },
    {
      Header: "Box 3",
      id: "deelBox3",
      Cell: (c): ReactElement => createSpanWithId(c.index, 4, bedragFormat(c.original.deelBox3))
    },
    {
      Cell: "DeleteButton"
    }
  ];

  const Leningdelen = ({ values }: LeningdelenProps): ReactElement => {
    return (
      <>
        <DataGrid
          masterDetail
          columns={leningdelenColumns}
          name="leningdelen"
          rowSelected={selectedState}
          validationSchema={leningdelenUitHetVerledenSchema}
          rowCaption="leningdeel"
          getNewRowValues={addLeningdeel}
        />

        {values.leningdelen.length > 0 && (
          <div>
            <h3>Leningdeel</h3>
            <LabeledSelectInput
              caption="Hypotheekvorm"
              readonly={!values.leningdelen[selected]?.editable}
              name={`leningdelen[${selected}].hypotheekvorm`}
              options={enum2options(AflossingsVormTypeMapper)}
            />
            {[
              String(AflossingsVormType.Belegging),
              String(AflossingsVormType.Spaarrekening),
              String(AflossingsVormType.Spaar),
              String(AflossingsVormType.Hybride),
              String(AflossingsVormType.Levensverzekering)
            ].some((val): boolean => val === values.leningdelen[selected].hypotheekvorm) && (
                <LabeledCurrencyInput
                  disabled={!values.leningdelen[selected]?.editable}
                  caption="Opgebouwde waarde"
                  name={`leningdelen[${selected}].opgebouwdeWaarde`}
                />
              )}

            <LabeledCurrencyInput
              caption="Hypotheekbedrag"
              disabled={!values.leningdelen[selected]?.editable}
              name={`leningdelen[${selected}].hypotheekbedrag`}
            />
            <LabeledJaarMaandInput
              caption="Looptijd"
              disabled={!values.leningdelen[selected]?.editable}
              name={`leningdelen[${selected}].looptijd`}
            />
            <LabeledCurrencyInput
              caption="Deel box3"
              disabled={!values.leningdelen[selected]?.editable}
              name={`leningdelen[${selected}].deelBox3`}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <AdviesBoxFormik<LeningdelenUitHetVerledenType>
      initialValues={{ ...data }}
      validationSchema={leningdelenUitHetVerledenSchema}
      onSave={(values): void => {
        onSave && onSave(values.leningdelen);
        closeModal && closeModal();
      }}
      closeModal={closeModal}
      render={({ values, setValues, submitForm }: FormikProps<LeningdelenUitHetVerledenType>): ReactElement => (
        <>
          <Modal
            title={MeeTeNemenLeningdelenModal.displayName}
            onSubmitClick={submitForm}
            onCancelClick={closeModal}
            body={<Leningdelen setValues={setValues} values={values} />}
          />
        </>
      )}
    />
  );
};

MeeTeNemenLeningdelenModal.displayName = "Leningdelen uit het verleden";
export default MeeTeNemenLeningdelenModal;
