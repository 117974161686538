import { MaandlastenInput } from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import { maandlastenBerekeningSchema } from "./maandlasten-berekening-schema";
import { mapLocalDateToString } from "adviesbox-shared";

export const mapMaandlastenBerekeningUiToDl = createMapToDl(maandlastenBerekeningSchema).to<MaandlastenInput>({
  hypotheekvorm: v => v.uitgangspunten.hypotheekVorm,
  hypotheekBedrag: v => v.uitgangspunten.hypotheekBedrag ?? 0,
  rentepercentage: v => v.uitgangspunten.rentePercentage ?? 0,
  ingangsdatum: v => mapLocalDateToString(v.uitgangspunten.ingangsDatum) ?? "",
  looptijd: v =>
    (v.uitgangspunten.looptijd?.jaren || /* istanbul ignore next */ 0) * 12 +
    (v.uitgangspunten.looptijd?.maanden || /* istanbul ignore next */ 0)
});
