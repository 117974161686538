/* istanbul ignore file */
import { LocalDate } from "@js-joda/core";
import { jaarMaandInMaanden, mapLocalDateToString } from "adviesbox-shared";
import {
  MaximaleHypotheekAanvragerInput,
  MaximaleHypotheekInput,
  ToetsrenteBerekeningOptions,
  MaximaleHypotheekLeningdeelInput,
  AflossingsVormType,
  MaximaleHypotheekUitgangspuntenInput
} from "../../.generated/berekeningen-forms/berekeningen-formstypes";
import { createMapToDl } from "../../shared/utils/create-map-to-dl";
import {
  aanvragerSchema,
  maximaleHypotheekSchema,
  modalLeningdelenSchema,
  uitgangspuntenSchema
} from "./maximale-hypotheek-schema";

const mapAflossingsvorm = (vorm: AflossingsVormType): AflossingsVormType => {
  switch (vorm) {
    case AflossingsVormType.Aflosvrij:
      return AflossingsVormType.Aflosvrij;
    case AflossingsVormType.Annuïteit:
      return AflossingsVormType.Annuïteit;
    case AflossingsVormType.AnnuïteitUitgesteld:
      return AflossingsVormType.AnnuïteitUitgesteld;
    case AflossingsVormType.Belegging:
      return AflossingsVormType.Belegging;
    case AflossingsVormType.Hybride:
      return AflossingsVormType.Hybride;
    case AflossingsVormType.Krediet:
      return AflossingsVormType.Krediet;
    case AflossingsVormType.Levensverzekering:
      return AflossingsVormType.Levensverzekering;
    case AflossingsVormType.Lineair:
      return AflossingsVormType.Lineair;
    case AflossingsVormType.Spaar:
      return AflossingsVormType.Spaar;
    case AflossingsVormType.Spaarrekening:
      return AflossingsVormType.Spaarrekening;
    case AflossingsVormType.UnitLinked:
      return AflossingsVormType.UnitLinked;
    default:
    case AflossingsVormType.Geen:
      return AflossingsVormType.Geen;
  }
};

const mapLeneingdeelUitHetVerleden = createMapToDl(modalLeningdelenSchema).to<MaximaleHypotheekLeningdeelInput>({
  box3Bedrag: v => v.deelBox3,
  leningdeelBedrag: v => v.hypotheekbedrag,
  leningdeelId: () => "",
  opgebouwdeWaardeBedrag: v => v.opgebouwdeWaarde,
  restantLooptijdInMaanden: v => jaarMaandInMaanden(v.looptijd),
  aflossingsvorm: v => mapAflossingsvorm(v.hypotheekvorm)
});

const mapAanvrager1 = createMapToDl(aanvragerSchema).to<MaximaleHypotheekAanvragerInput>({
  aowDatum: v => v.aowLeeftijdAanvrager1?.toString(),
  geboortedatum: v => mapLocalDateToString(v.geboortedatumAanvrager1),
  teBetalenAlimentatie: v => v.alimentatieTeBetalenAanvrager1,
  teOntvangenAlimentatie: v => v.alimentatieTeOntvangenAanvrager1,
  eindDatumTeOntvangenAlimentatie: v =>
    v.alimentatieTeOntvangenAanvrager1 && v.alimentatieTeOntvangenAanvrager1 > 0
      ? LocalDate.now()
          .plusYears(v.alimentatieTeOntvangenEinddatumAanvrager1?.jaren || 0)
          .plusMonths(v.alimentatieTeOntvangenEinddatumAanvrager1?.maanden || 0)
          .toString()
      : null,
  pensioenInkomen: v => v.pensioenInkomenAanvrager1.bedrag,
  toetsInkomen: v => v.toetsinkomenAanvrager1
});

const mapAanvrager2 = createMapToDl(aanvragerSchema).to<MaximaleHypotheekAanvragerInput>({
  aowDatum: v =>
    v.alimentatieTeOntvangenAanvrager2 && v.alimentatieTeOntvangenAanvrager2 > 0
      ? v.aowLeeftijdAanvrager2.toString()
      : null,
  geboortedatum: v => v.geboortedatumAanvrager2 && v.geboortedatumAanvrager2.toString(),
  teBetalenAlimentatie: v => v.alimentatieTeBetalenAanvrager2,
  teOntvangenAlimentatie: v => v.alimentatieTeOntvangenAanvrager2,
  eindDatumTeOntvangenAlimentatie: v =>
    v.alimentatieTeOntvangenEinddatumAanvrager2 &&
    LocalDate.now()
      .plusYears(v.alimentatieTeOntvangenEinddatumAanvrager2?.jaren || 0)
      .plusMonths(v.alimentatieTeOntvangenEinddatumAanvrager2?.maanden || 0)
      .toString(),
  pensioenInkomen: v => v.pensioenInkomenAanvrager2.bedrag,
  toetsInkomen: v => v.toetsinkomenAanvrager2
});

const mapUitgangspunten = createMapToDl(uitgangspuntenSchema).to<MaximaleHypotheekUitgangspuntenInput>({
  box3Deel: v => v.box3gedeelteBedrag,
  erfpachtCanon: v => v.erfpachtcanonBedrag,
  marktwaarde: v => v.marktwaardeBedrag,
  tarievenNorm: v => {
    return { jaar: +v.nhgHypotheeknorm, maand: 1 };
  },
  gewensteHypotheek: v => v.gewensteHypotheekBedrag,
  looptijd: v => v.looptijd,
  nhg: v => v.nhg,
  energieklasse: v => (v.energielabel ? parseInt(v.energielabel) : null)
});

export const mapMaximaleHypotheekBerekeningUiToDl = createMapToDl(maximaleHypotheekSchema).to<MaximaleHypotheekInput>({
  aanvragers: v =>
    v.aanvrager.medeAanvrager ? [mapAanvrager1(v.aanvrager), mapAanvrager2(v.aanvrager)] : [mapAanvrager1(v.aanvrager)],
  gewensteRentevastperiodeInMaanden: v =>
    v.toetsrente.berekeningOpBasis === ToetsrenteBerekeningOptions.GewensteRentevastperiode
      ? (v.toetsrente.gewensteRentevastperiode || 0) * 12
      : null,
  leningdelenUitVerleden: v => v.leningdelenUitHetVerleden.leningdelen.map(c => mapLeneingdeelUitHetVerleden(c)),
  leningdelenUitVerledenMeenemen: v => v.leningdelenUitHetVerleden.meenemen,
  kredietToetslast: v => v.toetsrente.kredietToetslastBedrag,
  specificatieToetsrentePercentage: v =>
    v.toetsrente.berekeningOpBasis === ToetsrenteBerekeningOptions.SpecificatieToetsrente
      ? v.toetsrente.toetsrente
      : null,
  toetsrenteBerekeningOpBasisVan: v => v.toetsrente.berekeningOpBasis,
  uitgangspunten: v => mapUitgangspunten(v.uitgangspunten),
  vrijVermogen: v => v.toetsrente.vrijvermogenBedrag
});
